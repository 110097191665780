<template>
  <div class="positive-content">
    <div class="mgb10"><el-button type="primary" size="medium" @click="addPositiveRemind">添加</el-button></div>
    <div v-for="(itr,index) in remindList" class="list-group" :key="'remindList' + index">
      <el-form :model="itr" :ref="'remindForm_'+index" :rules="remindFormRules[index]" size="medium" label-width="150px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="入职主体" prop="subjectId">
              <el-select v-model="itr.subjectId" :disabled="!itr.canEdit">
                <el-option v-for="option in Contracts" :key="'contracts' + option.value" :value="option.value" :label="option.text"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="type==1?'试用期结束前':'合同到期前'" prop="beforeDate">
              <el-input v-model="itr.beforeDate" :disabled="!itr.canEdit" title="最多可有5组数字" placeholder="输入数字，多组数字请用,分隔" oninput="value=value.replace(/[^\d\,]/g,'')"></el-input>天
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="type==1?'试用期结束后':'合同到期后'" prop="afterDate">
              <el-input v-model="itr.afterDate" :disabled="!itr.canEdit" title="最多可有5组数字" placeholder="输入数字，多组数字请用,分隔" oninput="value=value.replace(/[^\d\,]/g,'')"></el-input>天
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="通知人员" prop="noticeUserList"  class="required">
              <el-button
                  @click="addUser(index)"
                  class="add-button"
                  size="mini"
                  :disabled="!itr.canEdit"
              >+</el-button>
              <div
                  v-for="(e, i) in itr.noticeUserList"
                  :key="'noticeUserList' + i"
                  class="select-person"
              >
                <div class="selected-username">
                  <span :title="e.deptName">{{ e.name }}</span>
                  <span class="del-user" title="删除" @click="removeUser(i,index)" v-if="itr.canEdit"></span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="24">
            <el-form-item  prop="noticeParty">
              <el-checkbox v-model="itr.noticeParty" style="color:#5c6be8"  :disabled="!itr.canEdit" :label="type==1?'默认通知转正员工':'默认通知合同签订员工'"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监控时间">
              <el-time-picker clearable v-model="itr.cron" :disabled="!itr.canEdit" placeholder="请选择时间" value-format="HH:mm:ss"></el-time-picker>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div class="bottom-btn textRight">
        <el-button type="primary" size="medium" @click="editPositiveRemind(index)" v-if="!itr.canEdit">编辑</el-button>
        <el-button type="primary" size="medium" @click="savePositiveRemind(itr,index)" v-if="itr.canEdit" :loading="itr.isSubmitting">保存</el-button>
        <el-button type="danger" size="medium" @click="deletePositiveRemind(itr,index)">删除</el-button>
      </div>
    </div>
    <template v-if="remindList.length">
      <select-approval v-if="showSelectPersonTree"
                      :visible.sync="showSelectPersonTree"
                       title="选择人员"
                       @changeVisible="changeApproval(false)"
                       :origin-select="remindList[editIndex].noticeUserList"
                       @confirm="(v) => confirmApproval(v,editIndex)"
                       multiple
      ></select-approval>
    </template>
  </div>
</template>

<script>
import SelectApproval from "../../../../../components/selectApproval";
const validateTimeDate = (rule,value,callback)=>{
  const reg = /^\d+(,\d+){0,4}$/;
  if(value&&!reg.test(value)){
    callback(new Error('请输入数字,多组数字请用,分隔,最多可有5组,eg:1,2,3'))
  }else{
    callback();

  }
}
const validatePerson = (rule,value,callback)=>{
 if(value&&value.length==0){
   callback(new Error('请添加通知人员'))
 }else{
   callback();
 }
}
export default {
  name: "dateRemind",
  components: {SelectApproval},
  data(){
    return {
      Contracts:[],
      remindList:[],
      showSelectPersonTree:false,
      editIndex:0,
      remindFormRules:[],
    }
  },
  props:{
    type:{
      type:Number,
      default:1
    }
  },
  created() {
    this.getContracts();
    this.getList();
  },
  methods: {
    initRuleForm(tag,index){
      if(tag==='add'){
        this.remindFormRules.push({
          subjectId:[this.$formValidator.empty('入职主体','change','number')],
          noticeParty:[{required:true,message:'请选择',trigger:'change'}],
          beforeDate:[{validator:validateTimeDate,trigger:'blur'}],
          afterDate:[{validator:validateTimeDate,trigger:'blur'}],
          noticeUserList:[{validator:validatePerson,trigger:'change'}]
        })
      }else{
        this.remindFormRules.splice(index,1)
      }
    },
    getContracts() {
      this.$api.personnel.getContractsList({pageNum: 1, pageSize: 10000}).then(response => {
        this.Contracts = response.data;
      })
    },

    getList() {
      this.$api.personnel.getDateNoticeList({noticeType:this.type}).then(response => {
        if(response.data&&response.data.length){
          response.data.forEach((item,index)=>{
            this.initRuleForm('add')
          })
        }
        this.remindList = response.data
      })
    },

    savePositiveRemind(data,index){
      const formId = `remindForm_${index}`;
      this.$refs[formId][0].validate((valid) =>{
        if(valid){
          this.$set( data,'isSubmitting',true);
          let param = {
            id:data.id||'',
            subjectId:data.subjectId,
            afterDate:data.afterDate||'',
            beforeDate:data.beforeDate||'',
            cron:data.cron||'',
            noticeParty:data.noticeParty,
            noticeUserList:data.noticeUserList||'',
          }
          this.$api.personnel.saveDateNoticeList({...param,noticeType:this.type}).then(response=>{
            this.$message.success('保存成功');
            this.$set( data,'canEdit',false);
            this.$set( data,'id',response.data.id);
            this.$set( data,'isSubmitting',false);
          }).catch(err=>{
            this.$set( data,'isSubmitting',false)
          })
        }
      })

    },

    deletePositiveRemind(itr,index){
      this.$confirm('是否删除当前数据？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        if(itr.id){
          this.$api.personnel.delDateNoticeList(itr.id).then(response=>{
            this.remindList.splice(index,1);
            this.$message.success('删除成功');
            this.initRuleForm('del',index);
          })
        }else{
          this.remindList.splice(index,1)
          this.$message.success('删除成功');
          this.initRuleForm('del',index);

        }
      })
    },

    addPositiveRemind(){
      this.$set(this.remindList,this.remindList.length,{
        id:'',
        subjectId:this.Contracts[0].value,
        canEdit:true,
        noticeParty:true,
        noticeUserList:[],
        isSubmitting:false,
      })
      this.initRuleForm('add')
    },

    editPositiveRemind(index){
      this.$set( this.remindList[index],'canEdit',true)
    },

    addUser(index){
      this.showSelectPersonTree = true;
      this.editIndex = index;
    },

    changeApproval() {
      this.showSelectPersonTree = false
    },

    confirmApproval(value,index){
      this.remindList[index].noticeUserList = value
      const formId = `remindForm_${index}`;
      this.$refs[formId][0].validateField('noticeUserList')
    },
    removeUser(userIndex,listIndex){
      this.remindList[listIndex].noticeUserList.splice(userIndex, 1);
    }
  }
}
</script>

<style scoped lang="scss">
:v-deep .el-checkbox__input.is-checked + .el-checkbox__label{
  color: #606266;
}
::v-deep .el-checkbox__label {
  font-size: 14px !important;
  color:#606266 !important;
}
.positive-content{
  width: 80%;
  margin: 0 auto;
  .list-group{
    border: 1px solid #d2d2d2;
    padding: 20px;
    border-radius: 8px;
    & +.list-group{
      margin-top: 10px;
    }
    .el-input, .el-select{
      width: 220px;
      margin-right: 5px;
    }
    .add-button{
      margin-right: 10px;
    }
    .select-person{
      display: inline-block;
      cursor: pointer;
      margin:0 25px 0 0;
      .selected-username{
        display: inline-block;
        line-height: 1.5;
        background: #f3f3f3;
        border-radius: 4px;
        padding: 2px 10px;
        position: relative;
        .del-user{
          position: absolute;
          top: -5px;
          right: -10px;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: #565656;

          &:after{
            content:'×';
            font-size: 14px;
            color: #fff;
            position: absolute;
            top: -3px;
            right: 4px;
          }
        }
      }
    }
  }
}
</style>